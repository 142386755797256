import React from "react"
import styles from "./squareInput.module.css"

export default function SquareInput({placeholder, type, value, onChange}) {
    return (
      <div className={styles.main}>
            <input value={value} placeholder={placeholder} type={type} className={styles.input} onChange={e=>onChange(e.target.value)}/>
      </div>
    )
}
