import React from "react"
import styles from "./title.module.css"

export default function Title({ title, subtitle }) {
    return (
      <div className={styles.main}>
          <h1 className={styles.title}>{title}</h1>
          <div className={styles.subtitle}>{subtitle}</div>
      </div>
    )
}
