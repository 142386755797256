import React, { useState } from "react"
import styles from "./contact.module.css"
import SquareInput from "../squareInput"
import SquareTextArea from "../squareTextArea"
import buttonStyles from "../../styles/buttons.module.css"
import Title from "../title"
import validateEmail from "../../utils/checkEmail"
import * as classnames from "classnames"

export default function Contact() {
    const [email, setEmail] = useState("")
    const [name, setName] = useState("")
    const [message, setMessage] = useState("")

    const [sending, setSending] = useState(false)

    const [errorMessage, setErrorMessage] = useState()
    const [successMessage, setSuccessMessage] = useState()


    async function onFormSubmitClicked() {
        setErrorMessage(null);
        setSuccessMessage(null);
        setSending(true);

        if (!email && email.length < 3) {
            setErrorMessage("All fields are required")
            setSending(false);
            return
        }

        if (!name && name.length < 3) {
            setErrorMessage("All fields are required")
            setSending(false);
            return
        }

        if (!message && message.length < 3) {
            setErrorMessage("All fields are required")
            setSending(false);
            return
        }

        const isEmailValid = validateEmail(email)

        if (!isEmailValid) {
            setErrorMessage("Please use a valid email address")
            setSending(false);
            return
        }

        const token = await getRCT()

        const payload = {
            email: email,
            name: name,
            message: message,
            token: token
        }

        const formStatus = await submitForm(payload)
        setSending(false);
        setName("");
        setEmail("");
        setMessage("");

        if(formStatus.err){
            setErrorMessage(formStatus.message);
        }

        if(formStatus.err === false){
            setSuccessMessage(formStatus.message);
        }

    }

    function getRCT() {
        return new Promise(resolve => {
            window.grecaptcha.execute("6LcLkOoZAAAAADp4ux6E5KJkfNBXabBh2fHFYQH-", { action: "submit" }).then(token => {
                return resolve(token)
            })
        })
    }

    function submitForm(payload) {
        return new Promise(resolve => {
            fetch("https://shop.loribooks.com/api/public/contact", {
            // fetch("http://localhost:4000/public/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(payload)
            }).then(function(response) {
                if (response.status !== 200) {
                    return resolve({ err: true, message: "There was a problem submitting your form" })
                }
                return response.json()
            }).then(function(data) {
                return resolve({ err: false, message: `Thank you! We'll get back to you soon.` })
            }).catch(function(ex) {
                return resolve({ err: true, message: "There was a problem submitting your form" })
            })
        })
    }

    return (
      <div id="contact" className={styles.main}>
          <div className={styles.title}>
              <Title title="Contact Us"
                     subtitle="If you would like to get in touch with us, please fill out the form below:"/>
          </div>
          <div className={styles.contactFormWrapper}>

              {successMessage &&
              <div className={styles.successMessage}>{successMessage}</div>
              }
              {errorMessage &&
              <div className={styles.errorMessage}>{errorMessage}</div>
              }

              <div className={styles.contactForm}>

                  <div className={styles.contactFormLine}>
                      <div className={styles.contactFormInput}>
                          <SquareInput placeholder='Name' type='text' value={name} onChange={value => setName(value)}/>
                      </div>
                      <div className={styles.contactFormInput}>
                          <SquareInput placeholder='Email' type='email' value={email}
                                       onChange={value => setEmail(value)}/>
                      </div>
                  </div>
                  <div className={styles.contactFormLine}>
                      <div className={styles.contactFormInput}>
                          <SquareTextArea placeholder='Message' type='text' value={message}
                                          onChange={value => setMessage(value)}/>
                      </div>
                  </div>

                  <div className={styles.recaptcha}>
                      This site is protected by reCAPTCHA and the Google
                      <a className={styles.recaptchaLink} href="https://policies.google.com/privacy">Privacy Policy</a> and
                      <a className={styles.recaptchaLink} href="https://policies.google.com/terms">Terms of Service</a> apply.
                  </div>


                  <div className={styles.button}>
                      <div className={classnames(buttonStyles.largeButton, { [buttonStyles.disabled]: sending })}
                           onClick={onFormSubmitClicked}>
                          {sending === true ? "SENDING..." : "SUBMIT"}
                      </div>
                  </div>

                  <div id="grecaptcha-box"></div>
              </div>
          </div>
      </div>
    )
}
