import React from "react"
import styles from "./squareInput.module.css"

export default function SquareTextArea({placeholder, type, value, onChange}) {
    return (
      <div className={styles.main}>
            <textarea id='square' value={value} onChange={e=>onChange(e.target.value)} rows={10} placeholder={placeholder} type={type} className={styles.input}/>
      </div>
    )
}
